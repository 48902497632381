import { useState, useEffect } from "react";
import theme from "../gatsby-plugin-theme-ui";

const useClientWindowSize = () => {
  const [clientWindowSize, setClientWindowSize] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });
  const [notReady, setClientReady] = useState(typeof window === "undefined");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setClientReady(false);

      const handleResize = () => {
        setClientWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  // Determine screen sizes based on clientWindowSize
  const smallScreen = clientWindowSize.width < theme.responsive.mediumLarge;
  const smallerScreen = clientWindowSize.width < theme.responsive.medium;
  const smallScreenUp = clientWindowSize.width > theme.responsive.small;
  const desktop = clientWindowSize.width > theme.responsive.mediumLarge;
  const mobile = clientWindowSize.width < theme.responsive.small;
  const tablet =
    clientWindowSize.width >= theme.responsive.small &&
    clientWindowSize.width < theme.responsive.large;
  const largeDesktop = clientWindowSize.width > theme.responsive.large;

  return {
    notReady,
    smallScreen,
    smallerScreen,
    smallScreenUp,
    desktop,
    mobile,
    tablet,
    largeDesktop,
    clientWindowSize,
  };
};

export default useClientWindowSize;
